import { ref } from 'vue'
import { AsyncData, useRuntimeConfig } from '#app'
import { User } from '~/models/User'
import { useAppStore } from '~/stores/app'

interface LoginResponse {
    token: string
}

export function useAuthV2 () {
  const config = useRuntimeConfig()
  const isAuth = ref(false)
  const url = config.public.api_base_url
  const appStore = useAppStore()

  const register = async (email: string, password: string) => {
    const {
      data,
      pending,
      error,
      execute
    } = await useFetch(url + '/users', {
      headers: { Accept: 'application/json' },
      method: 'POST',
      body: { email, plainPassword: password }
    }) as AsyncData<User, Error>
    if (data.value) {
      const { data: loginData } = await login(email, password)
      if (loginData.value) {
        appStore.setToken(loginData.value.token)
      }
    }
    return { data, pending, error, execute }
  }

  const login = async (email: string, password: string) => {
    const {
      data,
      pending,
      error,
      execute
    } = await useFetch(url + '/auth', {
      headers: { Accept: 'application/json' },
      method: 'POST',
      body: { email, password }
    }) as AsyncData<LoginResponse, Error>
    if (data.value) {
      appStore.setToken(data.value.token)
      await loadUserInfo()
    }
    return { data, pending, error, execute }
  }
  const loadUserInfo = async () => {
    const authBearer = 'Bearer ' + appStore.getToken
    const {
      data,
      pending,
      error,
      execute
    } = await useFetch<User>(url + '/me', { headers: { Accept: 'application/ld+json', Authorization: authBearer } })
    if (data.value) {
      isAuth.value = true
      appStore.setUser(data.value)
    } else if (error.value) {
      isAuth.value = false
      // Check if we have 400 error
      if (error.value?.data && (error.value?.data.code === 401 || error.value?.data.code === 403)) {
        appStore.init()
      }
    }
    return { data, pending, error, execute }
  }

  const setRole = async (role: string, id: string) => {
    const { data, pending, error } = await useFetch(url + '/users/' + id + role, {
      headers: { Accept: 'application/json', Authorization: ' Bearer ' + appStore.getToken },
      method: 'PUT',
      body: {}
    })
    return { data, pending, error }
  }

  return { isAuth, loadUserInfo, login, register, setRole/* user, pending, error, isAuth, logout, execute */ }
}
